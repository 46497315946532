import React from 'react';
import PropTypes from 'prop-types';
import Price from '../components/price';
import colors from '../config/color';

const ShoppingCheckPayChooses = props => {
  const { text, price } = props;

  return (
    <div className="content">
      <div className="item-content">
        {/* <input classname="" type="radio" name="superCommerce" value="CVS" /> {text} */}
        <form action="#">
          <div className="sty">
            <input type="radio" id="test1" name="radio-group" checked></input>
            <label For="test1">超商取貨</label>
            <Price price="0" />
          </div>
          <div className="sty">
            <input type="radio" id="test2" name="radio-group" checked></input>
            <label For="test2">宅配</label>
            <Price price="60" />
          </div>
        </form>
      </div>

      <style jsx>{`
        .content {
          margin-bottom: 18px;
        }

        .item-content {
          display: flex;
          flex-direction: column;
          font-size: 14px;
          font-family: SFProDisplay-Regular;
        }

        .sty {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin: 14px 0;
        }

        [type='radio']:checked,
        [type='radio']:not(:checked) {
          position: absolute;
          left: -9999px;
        }

        [type='radio']:checked + label,
        [type='radio']:not(:checked) + label {
          position: relative;
          padding-left: 28px;
          cursor: pointer;
          line-height: 20px;
          display: inline-block;
          color: ${colors['black-p']};
        }

        [type='radio']:checked + label:before,
        [type='radio']:not(:checked) + label:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 20px;
          height: 20px;
          border: 1px solid ${colors['black-p']};
          border-radius: 100%;
          background: #fff;
        }

        [type='radio']:checked + label:after,
        [type='radio']:not(:checked) + label:after {
          content: '';
          width: 16px;
          height: 16px;
          background: ${colors['purple']};
          position: absolute;
          top: 3px;
          left: 3px;
          border-radius: 100%;
          -webkit-transition: all 0.2s ease;
          transition: all 0.2s ease;
        }

        [type='radio']:not(:checked) + label:after {
          opacity: 0;
          -webkit-transform: scale(0);
          transform: scale(0);
        }

        [type='radio']:checked + label:after {
          opacity: 1;
          -webkit-transform: scale(1);
          transform: scale(1);
        }

        @media (min-width: 768px) {
        }

        @media (min-width: 1200px) {
        }
      `}</style>
    </div>
  );
};

ShoppingCheckPayChooses.propTypes = {
  text: PropTypes.string,
  price: PropTypes.number,
};

ShoppingCheckPayChooses.defaultProps = {
  text: '',
  price: '',
};

export default ShoppingCheckPayChooses;
