import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import ShoppingCheckPayTitle from '../components/shoppingCheckPayTitle';
import ShoppingCheckPayChooses from '../components/shoppingCheckPayChooses';
import colors from '../config/color';

const ShoppingCheckPay = () => {
  return (
    <div className="content">
      <ShoppingCheckPayTitle titleRight="配送方式" titleLeft="運費" />
      <ShoppingCheckPayChooses />
      <ShoppingCheckPayTitle titleRight="配送資料" titleLeft="" />
      <div className="info-content">
        <div className="info-sty">
          <p>配送門市：</p>
          <div className="info-btn">選擇門市</div>
        </div>

        <div className="info-sty">
          <p>配送地址：</p>
          <div className="info-btn">地址填寫</div>
        </div>

        <div className="info-sty">
          <p>配送地址：</p>
          <div className="info-address">106台北市松山區敦化南路一段</div>
          <img src="/images/ic_edit@3x.png"></img>
        </div>

        <form id="survey-form">
          <div className="form-content">
            <div className="label">
              <label id="name" For="name">
                收件人：　
              </label>
            </div>
            <div className="addressee-input">
              <input
                type="text"
                id="name"
                className="input-field"
                required
                placeholder="收件人姓名"
              ></input>
            </div>
          </div>
          <div className="prompt-content">請填寫收件人</div>

          <div className="form-content">
            <div className="label">
              <label id="phone" For="phone">
                聯絡電話：
              </label>
            </div>
            <div className="telephone-input">
              <input
                type="phone"
                id="name"
                className="input-field"
                required
                placeholder="聯絡電話"
              ></input>
            </div>
          </div>
          <div className="prompt-content">請填聯絡電話</div>
        </form>
      </div>

      <style jsx>{`
        .content {
          margin-top: 36px;
          display: flex;
          flex-direction: column;
          font-size: 14px;
          font-family: SFProDisplay-Regular;
          color: ${colors['black-p']};
        }

        .info-sty {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          margin: 14px 0;
          position: relative;
        }

        .info-btn {
          padding: 8px 12px;
          background-color: ${colors['slime-green']};
          border-radius: 5px;
        }

        p {
          min-width: 70px;
        }

        img {
          width: 32px;
          position: absolute;
          right: 0;
        }

        form {
          display: flex;
          flex-direction: column;
        }

        .form-content {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
        }

        .input-field {
          font-size: 14px;
          font-family: SFProDisplay-Regular;
          color: ${colors['black-p']};
          border-bottom: 1px solid ${colors['black-p']};
          border-top: none;
          border-right: none;
          border-left: none;
          width: 200px;
        }

        input:focus {
          outline: none;
        }

        input::-webkit-input-placeholder {
          /* WebKit browsers */
          color: #e0e0e0;
        }

        input:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: #e0e0e0;
        }

        input::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: #e0e0e0;
        }

        input:-ms-input-placeholder {
          /* Internet Explorer 10+ */
          color: #e0e0e0;
        }

        .prompt-content {
          margin: 5px 0 14px 0;
          padding-left: 70px;
          font-size: 8px;
          color: #ff0080;
        }

        @media (min-width: 768px) {
        }

        @media (min-width: 1200px) {
        }
      `}</style>
    </div>
  );
};

ShoppingCheckPay.propTypes = {
  titleRight: PropTypes.string,
  titleLeft: PropTypes.string,
};

ShoppingCheckPay.defaultProps = {
  titleRight: '',
  titleLeft: '',
};

export default ShoppingCheckPay;
